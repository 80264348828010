@import url(https://fonts.googleapis.com/css2?family=Inter:wght@500&display=swap);
.btn-group-sm > .btn.btn-icon,
.btn.btn-icon.btn-sm {
    height: calc(1.35em + 1.1rem + 2px);
    width: calc(1.35em + 1.1rem + 2px);
}
.btn.btn-light {
    color: #7e8299;
    background-color: #f3f6f9;
    border-color: #f3f6f9;
}
.btn.btn-hover-danger.focus:not(.btn-text),
.btn.btn-hover-danger:focus:not(.btn-text),
.btn.btn-hover-danger:hover:not(.btn-text):not(:disabled):not(.disabled) {
    color: #fff !important;
    background-color: #f64e60 !important;
    border-color: #f64e60 !important;
}

.btn.btn-hover-primary:hover:not(.btn-text):not(:disabled):not(.disabled) {
    color: #fff !important;
    background-color: #6993ff !important;
    border-color: #6993ff !important;
}

.btn.btn-icon.btn-sm {
    height: calc(1.35em + 1.1rem + 2px);
    width: calc(1.35em + 1.1rem + 2px);
}
.btn.btn-icon {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 0;
}
.btn {
    outline: none !important;
    vertical-align: middle;
}
.btn-sm {
    padding: 0.55rem 0.75rem;
    font-size: 0.925rem;
    line-height: 1.35;
}

.label.label-lg.label-inline {
    width: auto;
}
.label.label-inline.label-lg {
    padding: 0.9rem 0.75rem;
}

.label.label-lg {
    height: 24px;
    font-size: 0.9rem;
}
.label.label-inline {
    border-radius: 0.42rem;
}
.label.label-light-success {
    color: #00D7D4;
    background-color: #c9f7f5;
}

.label.label-light-warning {
    color: black;
    background-color: #f1f65c;
}

.label {
    padding: 0;
    margin: 0;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-weight: 400;
}
.ml-5 {
    margin-left: 5px;
}
.mr-5 {
    margin-left: 5px;
}

.form-control.form-control-solid {
    background-color: #f3f6f9;
    border-color: #a1a2a8;
    color: #3f4254;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease,
        box-shadow 0.15s ease;
}
.h-auto {
    height: auto !important;
}
.pl-6,
.px-6 {
    padding-left: 1.5rem !important;
}
.pr-6,
.px-6 {
    padding-right: 1.5rem !important;
}

.pb-5,
.py-5 {
    padding-bottom: 1.25rem !important;
}
.pt-5,
.py-5 {
    padding-top: 1.25rem !important;
}
.h-45px {
    height: 45px !important;
}
.form-input-2 {
    margin-left: 20px;
}
.form-title {
    margin-top: 15px;
    margin-bottom: 45px;
}

.referral-button {
    background: #006FD8 !important;
}

.referral-button:hover {
    background: #155684 !important;
}

.back-button,
.referral-button {
    font-weight: 700 !important;
    font-size: 14px !important;
    line-height: 36px !important;
    border-radius: 10px !important;
    color: #fff !important;
    margin: 10px;
}
.back-button {
    background: #ee6e5a !important;
}

.back-button:hover {
    background: #f94c31 !important;
}

.mt-5,
.my-5 {
    margin-top: 1.25rem !important;
}
.mb-5 {
    margin-top: 1.25rem !important;
}
.form-group {
    margin-bottom: 1.75rem;
}

.support-add {
    background-color: #ffe2e5 !important;
    padding: 20px;
}
.rounded {
    border-radius: 0.42rem !important;
}
.label.label-light-danger {
    color: #f64e60;
    background-color: #ffe2e5;
}
.form-group {
    margin: 5px;
}
.select-input {
    height: 40px;
    border-radius: 5px;
    width: 100%;
    background: #ffff;
}
.description-btn {
    white-space: nowrap;
    width: 50px;
    overflow: hidden;
    text-overflow: ellipsis;
    border: 1px solid #000000;
}
.w-15 {
    width: 15%;
}
.feed-description {
    text-overflow: ellipsis;
    /* width: 50px; */
    /* Required for text-overflow to do anything */
    white-space: nowrap;
    overflow: hidden;
}

.feed-description {
    overflow: hidden;
    height: 1.2em;
    line-height: 1.2em;
    border: 1px dotted black;
    margin: 1em;
}
.feed-description::before {
    content: '';
    display: inline-block;
}
.inner-wrapper {
    display: inline-block;
    white-space: nowrap;
}

.table-responsive {
    color: #fafbfc;
}

.table {
    padding-right: 1rem !important;
}

.table-wrapper {
    min-width: 100%;
    background: #fbfbfb;
    padding: 10px;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
}

.table-title {
    min-width: 100%;
}

a .table-title h2 {
    margin: 8px 0 0;
    font-size: 22px;
}

.search-box {
    /* position: relative; */
    float: right;
    margin-right: 2rem;
}

.search-box input {
    height: 40px;
    border-radius: 5px;
    padding-left: 35px;
    border-color: #bfbfbf !important;
    box-shadow: none;
    background: #fff;
}

.search-box img {
    position: absolute;
    color: #a0a5b1;
    font-size: 19px;
    top: -28px;
    left: 0px;
}

table.table tr th,
table.table tr td {
    border-color: #e9e9e9;
    text-align: center !important;
    font-weight: bold;
    font-size: 13px;
}

table.table-striped tbody tr:nth-of-type(odd) {
    background-color: #fcfcfc;
}

table.table th i {
    font-size: 14px;
    margin: 0 5px;
    cursor: pointer;
}

table.table .last-child {
    width: 26% !important;
    padding-left: 0 !important;
}

table.table td a {
    color: #a0a5b1;
    display: inline-block;
}

table.table td i {
    font-size: 13px;
    color: #51626f;
}

.pagination {
    float: right;
    margin: 0 0 5px;
    margin-top: 20px !important;
}

.pagination li a {
    border-radius: 5px;
    padding: 1px 10px;
    margin: 0 3px;
}

.pagination .next a {
    background-color: #ffffff !important;
    color: #51626f;
    text-decoration: none;
}

.pagination .previous a {
    background-color: #ffffff !important;
    color: #51626f;
    text-decoration: none;
}
.pagination lic.active a {
    background: #03a9f4;
}

.active-page {
    background: #1bc5bd;
    color: #fff;
    text-decoration: none;
}
.pagination li a:hover {
    background-color: #1bc5bd !important;
}

.hint-text {
    float: left;
    margin-top: 6px;
    font-size: 95%;
}

.header-profile {
    position: absolute;
    right: 6rem;
    top: 0.5rem;
    width: 172px;
}

.login-pd {
    margin-top: 3rem;
    margin-right: 6rem;
}

.info-card {
    margin-top: 120px;
    margin-bottom: 50px;
}
@media (min-width: 1200px) {
    .table td {
        padding: 1rem !important;
    }
}
@media (max-width: 900px) {
    .table {
        width: 100%;
    }
    .header-profile {
        right: 4rem;
        top: 8rem;
    }
    .pd {
        padding: 6rem;
    }
    .login-pd {
        margin-top: 0rem;
        margin-right: 0rem;
        padding: 4rem;
    }
}
@media (max-width: 770px) {
    .search-box {
        margin-right: 0px;
    }
    .tablesm {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
    }
    .svg-icon-button {
        transform: none !important;
        transform: initial !important;
        margin-bottom: 5px;
        margin-top: 5px;
    }
    .btn-sm {
        margin-left: 5px;
    }
    .marksm {
        margin-top: 5px;
    }
}
@media (max-width: 550px) {
    table.table tr td {
        font-size: 7px;
    }
    table.table tr td a {
        font-size: 7px;
    }
}
@media (max-width: 450px) {
    .sm-btn {
        width: 80px;
    }
    .table td {
        padding: 0rem !important;
    }
}
@media (max-width: 375px) {
    .sm-btn {
        width: 80px;
    }
}

@media (min-width: 600px) {
    .MuiTab-root {
        min-width: 100% !important;
    }
}

.MuiTab-textColorInherit {
    color: inherit;
    opacity: 1 !important;
}

.MuiTab-root {
    padding: 6px 23px !important;
}

.mobile-button {
    margin-top: 5px;
}

.btn.btn-primary.dropdown-toggle:after {
    color: transparent !important;
}

.image-position {
    object-fit: cover;
    object-position: center;
}

.table {
    border-collapse: separate;
    border-spacing: 0 15px;
    margin-right: auto;
    /* background-color: #E5E5E5; */
}

.table thead tr th {
    border-top: none !important;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: #97a0b0;
}

.table-borderless td,
.table-borderless th {
    border: 0;
}

.table tbody tr {
    background: #ffffff;
    box-shadow: 10px 15px 20px rgba(84, 92, 101, 0.04);
    border-radius: 4px;
    height: 58px;
}

.nowrap {
    white-space: nowrap;
}
tbody tr td p {
    margin-bottom: 0rem;
}

.table td {
    word-wrap: break-word;
    border-top: none !important;
    color: #51626f;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 24px;
    vertical-align: middle !important;
}

.table-title-tag {
    color: #253b4b;
    font-size: 18px;
    line-height: 30px;
    font-weight: bold;
}

.status-button {
    position: absolute;
    right: 45px;
}

.filter-by-select {
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: #6e7592;
    opacity: 0.8;
}

.filtered-lable {
    color: #6e7592, 100%;
    font-size: 14px;
    font-weight: 600;
}

.task-content {
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    /* or 171% */

    margin-top: 10px;
    /* Black 60 */

    color: #7c8993;
}

.working-progress {
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    text-align: center;
    letter-spacing: -0.0884px;

    /* Black 80 */

    color: #51626f;
}

.add-user-show {
    background: #ffffff;
    /* Black 20 */
    border: 1px solid #d3d8db;
    box-sizing: border-box;
    border-radius: 3px;
}

.invite-name {
    font-weight: 500;
    font-size: 12px;
    line-height: 24px;
    /* identical to box height, or 200% */

    /* Black 80 */

    color: #51626f;
}

.dropdown-text-label {
    font-weight: 500 !important;
    font-size: 14px !important;
    line-height: 20px;
    /* identical to box height, or 143% */

    /* Black 60 */

    color: #7c8993 !important;
}

.courser-btn {
    background: none;
    border: none;
}

.circle-progressbar {
    min-height: 100vh;
    /* height of the browser viewport */
    display: flex;
    justify-content: center;
    align-items: center;
}

.header.header-fixed {
    height: 65px;
    position: -webkit-sticky !important;
    position: sticky !important;
    top: 0;
    right: 0;
    left: 0;
    z-index: 200;
}

.header-fixed .wrapper {
    padding-top: 0px !important;
}

.image-position {
    object-fit: cover;
    object-position: center;
}

.label.label-danger {
    color: #ffffff;
    background-color: #ee6e5a, 100% !important;
}

.symbol-round {
    width: 40px;
    height: 40px;
    border-radius: 30px;
}

.last-child-table {
    padding: 5px !important;
}

.add-btn {
    color: #51626f !important;
    font-weight: 500;
}
.fv-help-block {
    color: rgba(221, 13, 13, 0.959);
}
.left-right-pedding {
    padding: 0px 5px !important;
}
.circle-progressbar {
    min-height: 100vh;
    /* height of the browser viewport */
    display: flex;
    justify-content: center;
    align-items: center;
}

ul {
    margin: 0;
  }
  
  strong {
    display: inline-block;
    margin-top: 20px;
  }
  
  .dropzone {
    text-align: center;
    padding: 20px;
    border: 3px dashed #eeeeee;
    background-color: #fafafa;
    color: #bdbdbd;
  }
ul {
    margin: 0;
  }
  
  strong {
    display: inline-block;
    margin-top: 20px;
  }
  
  .dropzone {
    text-align: center;
    padding: 20px;
    border: 3px dashed #eeeeee;
    background-color: #fafafa;
    color: #bdbdbd;
  }
.medicine-history-table table.table tr th, .medicine-history-table table.table tr td {
    text-align: start !important;
}
.medicine-history-table .medicine-name{
    font-size: 16px !important;
}
/* @import url('https://fonts.googleapis.com/css?family=Muli'); */

* {
    /* font-family: 'Muli'; */
    font-family: 'Inter';
}

/* Box sizing rules */
*,
*::before,
*::after {
    box-sizing: border-box;
}

/* Remove default padding */
ul[class],
ol[class] {
    padding: 0;
}

/* Remove default margin */
body,
h1,
h2,
h3,
h4,
p,
ul[class],
ol[class],
li,
figure,
figcaption,
blockquote,
dl,
dd {
    margin: 0;
}

/* Set core body defaults */
body {
    min-height: 100vh;
    scroll-behavior: smooth;
    text-rendering: optimizeSpeed;
    line-height: 1.5;
}

/* Remove list styles on ul, ol elements with a class attribute */
ul[class],
ol[class] {
    list-style: none;
}

/* A elements that don't have a class get default styles */
a:not([class]) {
    -webkit-text-decoration-skip: ink;
            text-decoration-skip-ink: auto;
}

/* Make images easier to work with */
img {
    max-width: 100%;
    display: block;
}

/* Natural flow and rhythm in articles by default */
article > * + * {
    margin-top: 1em;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
    font: icon;
}

/* Remove all animations and transitions for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
    * {
        -webkit-animation-duration: 0.01ms !important;
                animation-duration: 0.01ms !important;
        -webkit-animation-iteration-count: 1 !important;
                animation-iteration-count: 1 !important;
        transition-duration: 0.01ms !important;
        scroll-behavior: auto !important;
    }
}

body {
    background-color: #fbfbfb;
    font-family: Muli;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 26px;
    color: #424147;
}

.add-btn {
    background-color: #006FD8 !important;
    border-color: #006FD8 !important;
    color: white !important;
}

.add-btn:hover {
    background-color: #155684 !important;
    border-color: #155684 !important;
    color: white !important;
}

/* .mainBlock-0-2-2 {
    padding: 15px;
    margin-left: 255px;
} */

.btn-size {
    transform: scale(0.9);
}

