.table-responsive {
    color: #fafbfc;
}

.table {
    padding-right: 1rem !important;
}

.table-wrapper {
    min-width: 100%;
    background: #fbfbfb;
    padding: 10px;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
}

.table-title {
    min-width: 100%;
}

a .table-title h2 {
    margin: 8px 0 0;
    font-size: 22px;
}

.search-box {
    /* position: relative; */
    float: right;
    margin-right: 2rem;
}

.search-box input {
    height: 40px;
    border-radius: 5px;
    padding-left: 35px;
    border-color: #bfbfbf !important;
    box-shadow: none;
    background: #fff;
}

.search-box img {
    position: absolute;
    color: #a0a5b1;
    font-size: 19px;
    top: -28px;
    left: 0px;
}

table.table tr th,
table.table tr td {
    border-color: #e9e9e9;
    text-align: center !important;
    font-weight: bold;
    font-size: 13px;
}

table.table-striped tbody tr:nth-of-type(odd) {
    background-color: #fcfcfc;
}

table.table th i {
    font-size: 14px;
    margin: 0 5px;
    cursor: pointer;
}

table.table .last-child {
    width: 26% !important;
    padding-left: 0 !important;
}

table.table td a {
    color: #a0a5b1;
    display: inline-block;
}

table.table td i {
    font-size: 13px;
    color: #51626f;
}

.pagination {
    float: right;
    margin: 0 0 5px;
    margin-top: 20px !important;
}

.pagination li a {
    border-radius: 5px;
    padding: 1px 10px;
    margin: 0 3px;
}

.pagination .next a {
    background-color: #ffffff !important;
    color: #51626f;
    text-decoration: none;
}

.pagination .previous a {
    background-color: #ffffff !important;
    color: #51626f;
    text-decoration: none;
}
.pagination lic.active a {
    background: #03a9f4;
}

.active-page {
    background: #1bc5bd;
    color: #fff;
    text-decoration: none;
}
.pagination li a:hover {
    background-color: #1bc5bd !important;
}

.hint-text {
    float: left;
    margin-top: 6px;
    font-size: 95%;
}

.header-profile {
    position: absolute;
    right: 6rem;
    top: 0.5rem;
    width: 172px;
}

.login-pd {
    margin-top: 3rem;
    margin-right: 6rem;
}

.info-card {
    margin-top: 120px;
    margin-bottom: 50px;
}
@media (min-width: 1200px) {
    .table td {
        padding: 1rem !important;
    }
}
@media (max-width: 900px) {
    .table {
        width: 100%;
    }
    .header-profile {
        right: 4rem;
        top: 8rem;
    }
    .pd {
        padding: 6rem;
    }
    .login-pd {
        margin-top: 0rem;
        margin-right: 0rem;
        padding: 4rem;
    }
}
@media (max-width: 770px) {
    .search-box {
        margin-right: 0px;
    }
    .tablesm {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
    }
    .svg-icon-button {
        transform: initial !important;
        margin-bottom: 5px;
        margin-top: 5px;
    }
    .btn-sm {
        margin-left: 5px;
    }
    .marksm {
        margin-top: 5px;
    }
}
@media (max-width: 550px) {
    table.table tr td {
        font-size: 7px;
    }
    table.table tr td a {
        font-size: 7px;
    }
}
@media (max-width: 450px) {
    .sm-btn {
        width: 80px;
    }
    .table td {
        padding: 0rem !important;
    }
}
@media (max-width: 375px) {
    .sm-btn {
        width: 80px;
    }
}

@media (min-width: 600px) {
    .MuiTab-root {
        min-width: 100% !important;
    }
}

.MuiTab-textColorInherit {
    color: inherit;
    opacity: 1 !important;
}

.MuiTab-root {
    padding: 6px 23px !important;
}

.mobile-button {
    margin-top: 5px;
}

.btn.btn-primary.dropdown-toggle:after {
    color: transparent !important;
}

.image-position {
    object-fit: cover;
    object-position: center;
}

.table {
    border-collapse: separate;
    border-spacing: 0 15px;
    margin-right: auto;
    /* background-color: #E5E5E5; */
}

.table thead tr th {
    border-top: none !important;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: #97a0b0;
}

.table-borderless td,
.table-borderless th {
    border: 0;
}

.table tbody tr {
    background: #ffffff;
    box-shadow: 10px 15px 20px rgba(84, 92, 101, 0.04);
    border-radius: 4px;
    height: 58px;
}

.nowrap {
    white-space: nowrap;
}
tbody tr td p {
    margin-bottom: 0rem;
}

.table td {
    word-wrap: break-word;
    border-top: none !important;
    color: #51626f;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 24px;
    vertical-align: middle !important;
}

.table-title-tag {
    color: #253b4b;
    font-size: 18px;
    line-height: 30px;
    font-weight: bold;
}

.status-button {
    position: absolute;
    right: 45px;
}

.filter-by-select {
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: #6e7592;
    opacity: 0.8;
}

.filtered-lable {
    color: #6e7592, 100%;
    font-size: 14px;
    font-weight: 600;
}

.task-content {
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    /* or 171% */

    margin-top: 10px;
    /* Black 60 */

    color: #7c8993;
}

.working-progress {
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    text-align: center;
    letter-spacing: -0.0884px;

    /* Black 80 */

    color: #51626f;
}

.add-user-show {
    background: #ffffff;
    /* Black 20 */
    border: 1px solid #d3d8db;
    box-sizing: border-box;
    border-radius: 3px;
}

.invite-name {
    font-weight: 500;
    font-size: 12px;
    line-height: 24px;
    /* identical to box height, or 200% */

    /* Black 80 */

    color: #51626f;
}

.dropdown-text-label {
    font-weight: 500 !important;
    font-size: 14px !important;
    line-height: 20px;
    /* identical to box height, or 143% */

    /* Black 60 */

    color: #7c8993 !important;
}

.courser-btn {
    background: none;
    border: none;
}

.circle-progressbar {
    min-height: 100vh;
    /* height of the browser viewport */
    display: flex;
    justify-content: center;
    align-items: center;
}

.header.header-fixed {
    height: 65px;
    position: sticky !important;
    top: 0;
    right: 0;
    left: 0;
    z-index: 200;
}

.header-fixed .wrapper {
    padding-top: 0px !important;
}

.image-position {
    object-fit: cover;
    object-position: center;
}

.label.label-danger {
    color: #ffffff;
    background-color: #ee6e5a, 100% !important;
}

.symbol-round {
    width: 40px;
    height: 40px;
    border-radius: 30px;
}

.last-child-table {
    padding: 5px !important;
}

.add-btn {
    color: #51626f !important;
    font-weight: 500;
}
.fv-help-block {
    color: rgba(221, 13, 13, 0.959);
}
.left-right-pedding {
    padding: 0px 5px !important;
}
.circle-progressbar {
    min-height: 100vh;
    /* height of the browser viewport */
    display: flex;
    justify-content: center;
    align-items: center;
}
