.btn-group-sm > .btn.btn-icon,
.btn.btn-icon.btn-sm {
    height: calc(1.35em + 1.1rem + 2px);
    width: calc(1.35em + 1.1rem + 2px);
}
.btn.btn-light {
    color: #7e8299;
    background-color: #f3f6f9;
    border-color: #f3f6f9;
}
.btn.btn-hover-danger.focus:not(.btn-text),
.btn.btn-hover-danger:focus:not(.btn-text),
.btn.btn-hover-danger:hover:not(.btn-text):not(:disabled):not(.disabled) {
    color: #fff !important;
    background-color: #f64e60 !important;
    border-color: #f64e60 !important;
}

.btn.btn-hover-primary:hover:not(.btn-text):not(:disabled):not(.disabled) {
    color: #fff !important;
    background-color: #6993ff !important;
    border-color: #6993ff !important;
}

.btn.btn-icon.btn-sm {
    height: calc(1.35em + 1.1rem + 2px);
    width: calc(1.35em + 1.1rem + 2px);
}
.btn.btn-icon {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 0;
}
.btn {
    outline: none !important;
    vertical-align: middle;
}
.btn-sm {
    padding: 0.55rem 0.75rem;
    font-size: 0.925rem;
    line-height: 1.35;
}

.label.label-lg.label-inline {
    width: auto;
}
.label.label-inline.label-lg {
    padding: 0.9rem 0.75rem;
}

.label.label-lg {
    height: 24px;
    font-size: 0.9rem;
}
.label.label-inline {
    border-radius: 0.42rem;
}
.label.label-light-success {
    color: #00D7D4;
    background-color: #c9f7f5;
}

.label.label-light-warning {
    color: black;
    background-color: #f1f65c;
}

.label {
    padding: 0;
    margin: 0;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-weight: 400;
}
.ml-5 {
    margin-left: 5px;
}
.mr-5 {
    margin-left: 5px;
}

.form-control.form-control-solid {
    background-color: #f3f6f9;
    border-color: #a1a2a8;
    color: #3f4254;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease,
        box-shadow 0.15s ease;
}
.h-auto {
    height: auto !important;
}
.pl-6,
.px-6 {
    padding-left: 1.5rem !important;
}
.pr-6,
.px-6 {
    padding-right: 1.5rem !important;
}

.pb-5,
.py-5 {
    padding-bottom: 1.25rem !important;
}
.pt-5,
.py-5 {
    padding-top: 1.25rem !important;
}
.h-45px {
    height: 45px !important;
}
.form-input-2 {
    margin-left: 20px;
}
.form-title {
    margin-top: 15px;
    margin-bottom: 45px;
}

.referral-button {
    background: #006FD8 !important;
}

.referral-button:hover {
    background: #155684 !important;
}

.back-button,
.referral-button {
    font-weight: 700 !important;
    font-size: 14px !important;
    line-height: 36px !important;
    border-radius: 10px !important;
    color: #fff !important;
    margin: 10px;
}
.back-button {
    background: #ee6e5a !important;
}

.back-button:hover {
    background: #f94c31 !important;
}

.mt-5,
.my-5 {
    margin-top: 1.25rem !important;
}
.mb-5 {
    margin-top: 1.25rem !important;
}
.form-group {
    margin-bottom: 1.75rem;
}

.support-add {
    background-color: #ffe2e5 !important;
    padding: 20px;
}
.rounded {
    border-radius: 0.42rem !important;
}
.label.label-light-danger {
    color: #f64e60;
    background-color: #ffe2e5;
}
.form-group {
    margin: 5px;
}
.select-input {
    height: 40px;
    border-radius: 5px;
    width: 100%;
    background: #ffff;
}
.description-btn {
    white-space: nowrap;
    width: 50px;
    overflow: hidden;
    text-overflow: ellipsis;
    border: 1px solid #000000;
}
.w-15 {
    width: 15%;
}
.feed-description {
    text-overflow: ellipsis;
    /* width: 50px; */
    /* Required for text-overflow to do anything */
    white-space: nowrap;
    overflow: hidden;
}

.feed-description {
    overflow: hidden;
    height: 1.2em;
    line-height: 1.2em;
    border: 1px dotted black;
    margin: 1em;
}
.feed-description::before {
    content: '';
    display: inline-block;
}
.inner-wrapper {
    display: inline-block;
    white-space: nowrap;
}
